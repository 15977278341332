<template>
    <div id="howtopage" style="width:100%;">
        
        <div style="margin: 30px;">
            <h4 class="small">
                How to use Nira?
            </h4>
        </div>

        <iframe width="800" height="500" src="https://www.youtube.com/embed/w-jSWH3jxck" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <div style="margin:48px">
            <el-button class="btn" style="width:200px" type="primary" size="large" @click="onGetStartedClick()" ><b>Back to Home</b></el-button>
        </div>
    
  </div>
</template>

<script>

export default {
  name: 'HowToPage',
  methods: {
      onGetStartedClick(){
        location.reload();  
      }
  }
}
</script>

<style scoped>


.block {
    text-align:left;
    padding-top: 10%;
    padding-left:10%;
    float: left;
}
.small {
    font-size: 20px;
    font-weight: 300;
}

.medium{
    font-size: 32px;
}

.big {
    font-size: 48px;
    margin-bottom: 20px;
}

.is-inline {
    display: inline-block;
}

a{
    color: #f8932c;
    cursor: pointer;
}

.is-button {
    padding: 10px;
    cursor: pointer;
    height: 40px;
    margin-top: 20px;
    width: 150px;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
    background: #f8932c;
    color: white;
}

.dim {
    width: 200px;
    margin-top: 50px;
}

.illus {
    padding-top: 8%;
    padding-right: 30%;
    float: right;
}

.monster {
  width: 190px;
  height: 240px;
  margin: 40px auto;
  background: url('https://treehouse-code-samples.s3.amazonaws.com/CSS-DD/codepen/blog/monster.png') left center;
  animation: play .8s steps(10) infinite;
}

@keyframes play {
	100% { background-position: -1900px; }
}
</style>