<template>
  <div class="footer brand-dark" :style="[toggleFooterPos? { position:'fixed' } : { }]">
    <div class="normal" style="margin:10px">&copy;2021 Nira by <b><a class="nolink" href="https://twitter.com/Bhuvanesh_Shan" target="_blank">BShan</a></b></div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    toggleFooterPos: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.normal{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: whitesmoke;
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

.nolink{
  text-decoration: none;
  color: whitesmoke;
}

.dimen{
  height:40px; 
  width:100%
}

</style>
