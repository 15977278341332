import { SVG, Color } from '@svgdotjs/svg.js' // eslint-disable-line no-unused-vars

function getRandomPallette(count, type){
    var colors = []
    for (let step = 0; step < count; step++) {
        colors.push(Color.random(type))
    }
    return Array.from(colors);
}

/**
 * Returns palatte with colors generated with random Hue but base color's Saturation and Value
 * @param {array of hex colors} baseColors 
 * @param {type of hues to be generated} type
 */
function getPalette(baseColors, type){
    var colors = []
    if(baseColors!=null && baseColors.length > 0){
        var hues = generateHues(type, baseColors.length);
        colors = getColorArrayFromHues(hues, baseColors);
    }
    return Array.from(colors);
}

/**
 * Returns palatte with colors generated with given Hues and the base color's Saturation and Value
 * @param {array of hex colors} baseColors 
 * @param {hues} different hues to be used
 */
function getPaletteFromGivenHues(baseColors, huesToUse){
    var colors = []
    if(baseColors!=null && baseColors.length > 0){
        var hues = populateHues(huesToUse, baseColors.length);
        //console.log("hues:"+hues+" baseColors:"+baseColors);
        colors = getColorArrayFromHues(hues, baseColors);
    }
    return Array.from(colors);
}

/**
 * Returns an array of hex colors from the supplied hues 
 */
function getColorArrayFromHues(hues, baseColors){
    var colors = []    
    for(var index in baseColors){
        var col = new Color(baseColors[index]).hsl();
        var newCol = getColorFromHueAndBase(hues[index], col, baseColors.length<3);
        colors.push(newCol.toHex())
    }
    return Array.from(colors);
}

function getColorFromHueAndBase(hue, baseCol, canModifySL){ // eslint-disable-line no-unused-vars
    var newCol = new Color({h:hue, s:baseCol.s, l:baseCol.l});
    if(canModifySL){ //Case if the palatte is 2 or less colors
        if(baseCol.toHex() == '#000000' || baseCol.toHex() == '#FFFFFF'){ //Case: So that we dont return black or white as the generated colors
            newCol = new Color({h:hue, s:getRandInRange(10,90), l:getRandInRange(10,90)});
        }else{ //Case: So that any grey would be able to show hue with some randomization in lightness and saturation
            if(newCol.s > 90){
                newCol.s = newCol.s - getRandInRange(10,20);
            }else if(newCol.s < 10){
                newCol.s = newCol.s + getRandInRange(10,20);
            }
            if(newCol.l > 90){
                newCol.l = newCol.l - getRandInRange(10,20);
            }else if(newCol.l < 10){
                newCol.l = newCol.l + getRandInRange(10,20);
            }
        }
    }
    /*if(canModifySL){
        console.log("can modify:"+newCol.s+" "+newCol.l);
        if(newCol.s == 100){
            newCol.s = newCol.s - getRandInt(20);
        }else if(newCol.s == 0){
            newCol.s = newCol.s + getRandInt(20);
        }
        
        if(newCol.l == 100){
            newCol.l = newCol.l - getRandInt(20);
        }else if(newCol.l == 0){
            newCol.l = newCol.l + getRandInt(20);
        }
    }*/
    return newCol;
}

function populateHues(huesToUse, count){
    var hues = []
    //console.log("huesToUse:"+huesToUse+" count:"+count)
    var repeats = count/huesToUse.length;
    for(const h of huesToUse){
        for(let c = 0; c < repeats; ++c ){ // eslint-disable-line no-unused-vars
            hues.push(h)
        }
    }
    //console.log("hues:"+hues);
    return hues.slice(0, count);
}

function generateHues(type, count){
    if(type == 'random'){
        return getRandomHues(count);
    }else if(type == 'mono'){
        return getMonoChromeHues(count);
    }else if(type == 'compli'){
        return getComplimentaryHues(count);
    }else if(type == 'triad'){
        return getTriadicHues(count);
    }
    return getRandomHues(count);
}

function getTriadicHues(count){
    var hues = []
    var chrome = getRandInt(359);
    for (let c = 0; c < count/3; c++) {
        hues.push(chrome + c*5);
    }
    chrome = (chrome + 120)%360;
    for (let c = 0; c < count/3; c++) {
        hues.push(chrome + c*5);
    }
    chrome = (chrome + 120)%360;
    for (let c = 0; c < count/3; c++) {
        hues.push(chrome + c*5);
    }
    return hues.slice(0, count);
}

function getComplimentaryHues(count){
    var hues = []
    var chrome = getRandInt(359);
    for (let c = 0; c < count/2; c++) {
        hues.push(chrome + c*5);
    }
    chrome = (chrome + 180)%360;
    for (let c = 0; c < count/2; c++) {
        hues.push(chrome + c*5);
    }
    return hues.slice(0, count);
}

function getMonoChromeHues(count){
    var hues = []
    var chrome = getRandInt(359);
    for (let c = 0; c < count; c++) {
        hues.push(chrome + c*5);
    }
    return hues;
}


function getRandomHues(count){
    var hues = []
    for (let c = 0; c < count; c++) {
        hues.push(getRandInt(359));
    }
    return hues;
}

function getRandInt(max){
    return Math.floor(Math.random()*max);
}

function getRandInRange(min, max){ 
    return min + Math.floor(Math.random() * (max-min));
}

export {getRandomPallette, getPalette, getPaletteFromGivenHues}