<template>
  <div class="workspace leftMargin">

    <div v-if="svgAvailable" class="optionsBar">
      
        <el-button class="bottomOptionsLeftItem bottomOptionsCircleItem" icon="el-icon-plus" @click="zoom(10)" circle size="mini"></el-button>
        <div class="bottomOptionsLeftItem bottomOptionsTextItem" id="zoomval">{{svgSize[0]}} x {{svgSize[1]}} px</div>
        <el-button class="bottomOptionsLeftItem bottomOptionsCircleItem" icon="el-icon-minus" @click="zoom(-10)" circle size="mini"></el-button>
        
        <el-button class="bottomOptionsRightItem btn" type="primary" size="medium" @click="save" >Download</el-button>
        <el-button class="bottomOptionsRightItem btntxt" type="text" size="medium" @click="newUpload">Upload New</el-button>
      
    </div>
    
    <div style="width: 100%;">
      
      <div id="svgHolder" :style="[svgAvailable? {padding: '48px'} : {}]" @mouseover="onElementClick"></div>

      <!--<div v-if="!svgAvailable" id="uploadHolder" style="padding: 20px">-->
        
      <!--<div :style="[svgAvailable? { visibility: 'collapse', display: 'none' } : { visibility: 'visible', padding: '20px'}]" id="uploadHolder">-->
        <div style="visibility: collapse; display: none" id="uploadHolder">
          <el-upload
            class="upload-demo"
            drag
            accept="image/svg"
            action=""
            :before-upload="onUpload">
            <i id="uploadComponent" class="el-icon-upload"></i>
            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
            <template #tip>
              <div class="el-upload__tip">
                svg files with a size less than 500kb
              </div>
            </template>
          </el-upload>
        <div style="width: 100%;height: 100px;" >
          <Samples v-on:on-sample-selected="onSampleClick($event)" />
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js' // eslint-disable-line no-unused-vars
import { getColorsFromSvg, setColorsOnSvg, setSizeTo, getSize, highlightColor, unhighlightColor } from '../js/recolor.js'
import { selectAtMouseXY, registerSelectionClick } from '../js/selection.js' // eslint-disable-line no-unused-vars
//import Samples from './Samples.vue'

export default {
  name: 'Workspace',
  components: {
    //Samples
  },
  props: { 
    newColors: Array, 
    newSVG: String,
    highlightColor: String,
    resetSvg: Boolean 
  },
  data() {
      return {
        zoomval: 100,
        svgSize: [0,0],
        svgContent: '',
        svgColors: [],
        svgAvailable: false,
        svgLoadError: '',
        originalSvgContent: ''
      }
    },
  watch: {
    resetSvg(newVal, oldVal){ // eslint-disable-line no-unused-vars
      if(newVal!=oldVal){
        console.log("resetting svg to original colors")
        this.resetSvgContent();
      }
    },
    highlightColor(newVal, oldVal){// eslint-disable-line no-unused-vars
      if(newVal){
        console.log("highlighting for col:"+newVal)
        this.setSvg(highlightColor(this.svgContent, newVal))
      }else{
        console.log("removing highlight for col:"+oldVal)
        if(oldVal){
          this.setSvg(unhighlightColor(this.svgContent, oldVal))
        }
      }
    },
    newSVG(newVal, oldVal){// eslint-disable-line no-unused-vars
      this.onSampleClick(newVal);
    },
    newColors(newVal, oldVal) { // eslint-disable-line no-unused-vars
      //console.log("watch new: "+newVal+" old:"+oldVal+" svgCol:"+this.svgColors);
      if(newVal!=null && newVal.size!=0){
        if(!this.arrayEquals(newVal, this.svgColors)){
          this.recolor(newVal, this.svgColors);
        }else{
          console.log("new and svg are equal!");
        }
      }else{
        console.log("new Val is empty!");
      }
    },
    zoomval(){
      this.setSvg(this.svgContent);
    },
    svgLoadError(){
      if(this.svgLoadError!=''){
        this.$message.error(this.svgLoadError);
      }
    }
  },
  methods:{

    //############# COLOR ################
    sampleColors(){
      var colors = getColorsFromSvg(this.svgContent);
      this.updateCurrentPallette(colors);
    },
    recolor(newColors, oldColors){
      //console.log("recoloring "+oldColors+" with "+newColors);
      var newSvgContent = setColorsOnSvg(this.svgContent, newColors, oldColors)
      this.setSvg(newSvgContent);
      this.updateCurrentPallette(newColors);
    },
    updateCurrentPallette(colors){
      this.svgColors = colors;
      //console.log("new event: sending colors"+colors);
      this.$emit("on-colors-from-svg", Array.from(colors));
    },

    //############## SVG MANIPULATION ###############
    setSvg(content){
      document.getElementById("svgHolder").innerHTML = content;
      this.svgContent = content;
    },
    resetSvgContent(){
      this.setSvg(this.originalSvgContent);
      this.sampleColors();
    },
    //PRIVATE
    cleanUpSize(sizePair){ 
      const regex = /[a-z%]/g;
      
      var found = sizePair[0].toString().match(regex);
      if(found!=null){
        for(var i of found){
          sizePair[0] = sizePair[0].replace(i,'');
        }
      }

      found = sizePair[1].toString().match(regex);
      if(found!=null){
        for(i of found){
          sizePair[1] = sizePair[1].replace(i,'');
        }
      }
      
      if(sizePair[0] == 0 && sizePair[1] == 0){
        sizePair[0] = 400;
        sizePair[1] = 400;
      }else if(sizePair[0] == 0){
        sizePair[0] = sizePair[1];
      }else if(sizePair[1] == 0){
        sizePair[1] = sizePair[0];
      }
      return sizePair;
    },
    //PRIVATE
    computeOptimalSize(content){ 
      this.svgSize = this.cleanUpSize(getSize(content));
      console.log("svgSize:"+this.svgSize);
      var maxDim = this.svgSize[0];
      if(this.svgSize[1]>this.svgSize[0]){
        maxDim = this.svgSize[1];
      }
      var factor = 800/maxDim
      console.log("factor: "+factor);
      if(factor<1){
        this.setSize(content, factor);
      }else{
        this.setSize(content, 1);
      }
    },
    //PRIVATE
    setSize(content, factor){ //1 will keep it same. 
      //console.log("Setting with factor "+factor);
      this.svgSize = [Math.floor(this.svgSize[0]*factor), Math.floor(this.svgSize[1]*factor)];
      var resized = setSizeTo(content, this.svgSize[0], this.svgSize[1]);
      this.setSvg(resized);
    },
    zoom(change){
      //this.zoomval = this.zoomval + change
      //change is +10% or -10%
      this.setSize(this.svgContent, (change+100)/100);
    },

    //########## NEW UPLOAD #############
    onUpload(file){ 
      console.log("file:"+file);
      if(file.type === 'image/svg+xml'){
        console.log("loading file type svg xml");
        this.loadSvg(file, false)
      }else{
        console.log("Not expected file type:"+file.type)
        this.$message.error("Oops, Not expected file type: "+file.type);
      }
    },
    onSampleClick(sampleSvgElement){ 
      //console.log("Clicked sample: "+sampleSvgElement);
      //sampleSvgElement = '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs"><rect width="10" height="10" x="200" y="200" fill="#ff0066"></rect><rect width="10" height="10" x="200" y="0" fill="#ff0066"></rect><rect width="10" height="10" x="0" y="200" fill="#ff0066"></rect><rect width="10" height="10" fill="#ff0066"></rect><rect width="10" height="10" x="100" y="100" fill="#ff0066"></rect></svg>'
      this.onSvgLoad(sampleSvgElement.toString());
      //this.$emit('on-sample-clicked-in-workspace');
      //this.loadSvg(samples[sampleIndex], true)
    },
    newUpload(){
      //Clearing all the values so that the ui is ready for new upload
      //document.getElementById("svgHolder").innerHTML = ''
      //this.svgAvailable = false;
      document.getElementById("uploadComponent").click()
      //this.$emit('ready-for-new-upload');
    },
    //PRIVATE
    loadSvg(file, isSample){ // eslint-disable-line no-unused-vars 
      if (file) {
        var reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = evt => this.onSvgLoad(evt.target.result)
        reader.onerror = e => this.svgLoadError = "Error reading file! Please try with a different svg file!"+e;
      }
    },
    //PRIVATE
    onSvgLoad(svgcont){ 
      if(svgcont!=null){
        this.svgAvailable = true;
        var content = svgcont;
        if(content.startsWith('<?xml')){
          console.log("starts with <?xml");
          content = this.stripXmlTag(content);
          //console.log("con:"+content);
        }
        this.setSvg(content); 
        this.computeOptimalSize(this.svgContent); //calculates size and sets on svg
        this.originalSvgContent = this.svgContent; //saves so that can be used when user resets
        this.sampleColors();
        this.$emit('refresh-palette');
        this.$emit('on-svg-added-in-workspace');
        //this.setSvg(registerSelectionClick(this.svgContent));
      }
    },
    //PRIVATE
    stripXmlTag(content){ 
      var start = content.indexOf('<svg');
      var sliced = content.slice(start);
      return sliced;
    },

    //############ SAVE ##################
    save(){
      var svgBlob = new Blob([this.svgContent], {type:"image/svg+xml;charset=utf-8"});
      var svgUrl = URL.createObjectURL(svgBlob);
      var downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = "nira-recolored.svg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      this.$message({
          message: 'Your recolored svg has been saved!',
          type: 'success'
        });
    },


    //########## SELECTION #############
    onElementClick(event){ // eslint-disable-line no-unused-vars
      /*var svgHolder = document.getElementById("svgHolder")
      var svgRect = svgHolder.getElementsByTagName('svg')[0].getBoundingClientRect();
      var x = event.pageX - svgRect.left 
      var y = event.pageY - svgRect.top 
      console.log("page: "+event.pageX+" "+event.pageY)
      console.log("svgh: "+svgRect.left+" "+svgRect.top)
      console.log("xyfi: "+x+" "+y)*/
      //var svgWithSelection = selectAtMouseXY(x, y, this.svgContent)
      //this.setSvg(svgWithSelection)
    },


    //############ UTIL ############
    arrayEquals(a, b) {
      return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
    },

    

    //######## UNUSED - CAN DELETE ###########
    updateSvg(file, isSample){
      this.svgAvailable = true;

      var obj = document.createElement('object');
      obj.setAttribute("id", "svgExternalObject")

      if(isSample){
        obj.type = 'image/svg+xml'
        obj.data = file;
        console.log(file);
        obj.setAttribute("width", "80%");
        this.zoomval = 80
      }else{
        obj.data = URL.createObjectURL(file);
        obj.onload = e => URL.revokeObjectURL(obj.data); // eslint-disable-line no-unused-vars
        obj.setAttribute("width", "50%");
        this.zoomval = 50
      }
      var svgHolder = document.getElementById("svgHolder")
      svgHolder.appendChild(obj);
    },
    doOnSvgLoad(){
      window.addEventListener("load", function() {
        this.updateColorInfo();
      });
    },
    verify(){
      this.updateColorInfo();
    },
    updateColorInfo(){
      var svgObject = document.getElementById('svgExternalObject').contentDocument;
      var svg = svgObject.getElementsByTagName('svg')[0];
      console.log(svg);
      var colors = this.getColorsFromSvg(svg);
      console.log(colors);
      //var colors = getColorsFromSvg(this.getObject());
      //console.log(colors);
      //this.$emit('myEvent')
    },
    getColorsFromSvg(obj){
      const colors = new Set();
      if(obj!=null){
        var svg = SVG(obj);
        this.loopAllChildren(svg, colors);
        /*svg.each(function(i, children) { 
          console.log(i+" child:"+children);
          //colors.add(this.attr('fill'));
        }, true);*/
      }else{
        console.log("obj is null!")
      }
      return colors;
    },
    getObject(){
      var svgHolder = document.getElementById("svgHolder")
      return svgHolder.getElementsByTagName('object')[0]
    },
    getSvgObject(){
      var svgHolder = document.getElementById("svgHolder")
      return svgHolder.getElementsByTagName('object')[0].contentDocument.documentElement;
    },
    loopAllChildren(svgEle, colorSet){
      var childs = svgEle.children()
      console.log(childs);
      childs.forEach(function (child) {
        console.log(child);
        colorSet.add(child.attr('fill'));
        this.loopAllChildren(child, colorSet);
      });
    }

  }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.leftMargin{
  margin-left: 42px;
}

.optionsBar{
  width: 66%;
  display: block;
  height: 40px;
  position: fixed;
  border-radius: 8px; 
}

.bottomOptionsTextItem{
  font-size:small;
  padding: 4px 4px;
}

.bottomOptionsCircleItem{
  width: 28px;
}

.bottomOptionsRightItem{
  float:  right;
  margin-left: 16px;
}

.bottomOptionsLeftItem{
  float: left;
  margin-right: 8px;
}

</style>
