<template>
  <div id="hue-slider-holder" style="padding:5px">
    <div class="hue-preview" :style="{background: 'hsl('+hue+',80%,60%)'}" />
    <input class="hue-slider" id="hue-slider" type="range" min="0" max="360" value="hue" v-on:input="onUserInput($event)"/>
  </div>
</template>

<script>
export default {
  name: 'HueSlider',
  props: {
    hue: Number
  },
  methods: {
    onUserInput(event){
      this.$emit('update:hue', Number(event.target.value));
      this.$emit("hue-update");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.hue-slider {
  appearance: none;
  vertical-align: middle;
  outline: none;
  position: relative;
  flex: 1;
  height: 10px;
  width: 200px;
  margin-left: 2vmin;
  border-radius: 5px;
  transition: all .3s;
  background: linear-gradient(
    to right,
    hsl(0, 80%, 60%) 0%,
    hsl(30, 80%, 60%) 8.3%,
    hsl(60, 80%, 60%) 16.6%,
    hsl(90, 80%, 60%) 25%,
    hsl(120, 80%, 60%) 33.3%,
    hsl(150, 80%, 60%) 41.6%,
    hsl(180, 80%, 60%) 50%,
    hsl(210, 80%, 60%) 58.3%,
    hsl(240, 80%, 60%) 66.6%,
    hsl(270, 80%, 60%) 75%,
    hsl(300, 80%, 60%) 83.3%,
    hsl(330, 80%, 60%) 91.6%,
    hsl(360, 80%, 60%) 100%
  );
}

.hue-preview{
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: inline-block;
  vertical-align: middle;
}


.leftAlign{
  float: left; 
}

.colorItem{
  display: inline-block;
  margin: 4px 2px 0px 2px;
  border-radius: 4px;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #c0c0c0;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  border: 1px solid #c0c0c0;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  border: 1px solid #c0c0c0;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}

</style>
