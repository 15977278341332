<template>
  <div class="colorspace elevatedStage inset max30">
    <div class="fullWidth" style="margin:10px 0px 10px 10px">
      <div class="leftAlign heading txt">Current</div>
      <el-button class="bottomOptionsRightItem btntxt" type="text" size="medium" @click="resetColors">Reset</el-button>
    </div>

    <div>
      <ColorPickerListHolder class="emptyholder" 
        :colors="curColors" 
        v-on:on-user-select-color-change="$emit('on-user-select-color-change', $event)"
        v-on:on-user-hover-on-color="$emit('on-user-hover-on-color', $event)"/> 
    </div>

    <div class="fullWidth" style="margin:20px 0px 10px 10px">
      <div class="leftAlign heading txt">Pallettes</div>
    </div>

    <div v-if='curColors.length>1' class="fullWidth" style="margin-bottom:5px">
      <el-button-group class="rightAlign" style="margin-top:2px">
        <el-button :class="{ btn: isPrimary('mono') }" :type="isPrimary('mono')" size="mini" @click="type='mono'">Mono</el-button>
        <el-button :class="{ btn: isPrimary('compli') }" :type="isPrimary('compli')" size="mini" @click="type='compli'">Duo</el-button>
        <el-button :class="{ btn: isPrimary('triad') }" v-if='curColors.length>2' :type="isPrimary('triad')" size="mini" @click="type='triad'">Triad</el-button>
        <el-button :class="{ btn: isPrimary('random') }" :type="isPrimary('random')" size="mini" @click="type='random'">I'm lucky</el-button>
      </el-button-group>
    </div>
    
    <div style="margin:10px">
      <ColorSliders :count='getSlidersCount()' v-on:on-user-select-hues-change="selectHues($event)" />
    </div>
    
    <div class="fullWidth" style="margin:5px 0px 10px 0px">
      <div class="subheading txt" style="display: inline-block; margin-right: 20px;">Suggestions</div>
      <el-button class="" icon="el-icon-refresh" size="mini" circle @click="populatePallette"></el-button>
    </div>

    <div id="suggestions">
      <ColorListHolder class="holder" v-for="(sug,index) in suggestionList" :key="sug" :colors="sug" size="32" @click="selectSuggestion(index)"/> 
    </div>

  </div>
</template>

<script>
import ColorListHolder from './ColorListHolder.vue'
import ColorPickerListHolder from './ColorPickerListHolder.vue'
import { getRandomPallette, getPalette, getPaletteFromGivenHues } from '../js/colgen.js' // eslint-disable-line no-unused-vars
import ColorSliders from './ColorSliders.vue'

export default {
  name: 'Colorspace',
  components: {
    ColorListHolder, ColorPickerListHolder, ColorSliders
  },
  props: { 
    curColors: Array,
    refreshPalette: Boolean
  },
  data() {
    return {
      suggestionList: [],
      type: 'mono'
    }
  },
  mounted(){
    this.populatePallette();
  },
  watch:{
    type(oldVal, newVal){ // eslint-disable-line no-unused-vars
      this.populatePallette();
    },
    refreshPalette(oldVal, newVal){ // eslint-disable-line no-unused-vars
      this.populatePallette();
    }
  },
  methods: {
    isPrimary(val){
      if(this.type == val){
        return 'primary'
      }else{
        return ''
      }
    },
    getSlidersCount(){
      switch(this.type){
        case 'mono':
          return 1;
        case 'compli':
          return 2;
        case 'triad':
          return 3;
        default:
          return 0;
      }
    },
    populatePallette(){
      console.log("val of refresh: "+this.refreshPalette)
      this.suggestionList = [];
      for (let step = 0; step < 5; step++) {
          /*var scheme = new ColorScheme;
          scheme.from_hue(step)         
              .scheme('triade')     
              .variation('soft'); 
          var colors = scheme.colors();*/
          //var colors = getPallette(this.curColors.length, this.type);
          var colors = getPalette(Array.from(this.curColors), this.type);
          this.suggestionList.push(Array.from(colors));
      }
    },
    selectSuggestion(index){
      this.$emit('on-user-select-color-change', this.suggestionList[index]);
    },
    selectHues(hues){
      //console.log("hue update:"+hues);
      this.$emit('on-user-select-color-change', getPaletteFromGivenHues(Array.from(this.curColors), Array.from(hues)));
    },
    resetColors(){
      this.$emit('on-reset-colors');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1); 
  -->
<style>

.max30{
  max-width: 30%;
}

.inset{
  padding: 12px;
  position:fixed;
  top:80px;
  z-index:100;
}

@media screen and (max-width: 800px) {
  .inset{
    padding: 12px;
    position: relative;
    z-index: 0;
    top:0px;
  }
  .max30{
    max-width: 100%;
  }
}

.leftAlign{
  float: left; 
}

.fullWidth{
  width: 100%; display: inline-block;
}

.holder{
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  border: whitesmoke 0px solid;
  margin-bottom: 4px;
}

.holder:hover{
  background-color: whitesmoke;
}

.bottomOptionsRightItem{
  float:  right;
  margin-right: 16px;
}

</style>
