<template>
  <div class="main" style="height:100%; width:100%;">

      <LandingPage v-if="!ready" v-on:on-sample-selected="onSampleClick($event)"/>

      <div :class="{ leftCol: !ready, readyLeftCol: ready}">
        <!--<LandPic v-if="!ready" />-->
        <Colorspace v-if="ready" 
          :curColors="svgColors" 
          :refreshPalette="refresh" 
          v-on:on-user-select-color-change="applyColors($event)"
          v-on:on-user-hover-on-color="userHoverOnCurrentColor($event)"
          v-on:on-reset-colors="resetSvgContent()"/>
      </div>

      <div :class="{ rightCol: !ready, readyRightCol: ready}">  
        <!--<LandText v-if="!ready"/>-->
        <Workspace 
          v-on:on-colors-from-svg="showColorsInCurrentPallete($event)" 
          v-on:refresh-palette="refreshPalettes()"
          v-on:ready-for-new-upload="readyForNewUpload($event)" 
          v-on:on-svg-added-in-workspace="notifyFooter()"
          :newColors="newColors" 
          :newSVG="newSample"
          :highlightColor="highlightCol"
          :resetSvg="resetFullSvg"/>
      </div>

      <div v-if="!ready">
        <ExplCards v-on:on-sample-selected="onSampleClick($event)"/>
      </div>

  </div>
</template>

<script>
import Workspace from './Workspace.vue'
import Colorspace from './Colorspace.vue'
//import LandText from './LandText.vue'
//import LandPic from './LandPic.vue'
import ExplCards from './ExplCards.vue'
import LandingPage from './LandingPage.vue'

export default {
  name: 'Main',
  components: {
    Workspace,
    Colorspace,
    //LandText,
    //LandPic,
    ExplCards,
    LandingPage
  },
  props: {},
  data(){
    return {
      svgColors: [],
      newColors: [],
      ready: false,
      workspaceSpan: 12,
      newSample: null,
      refresh: false,
      highlightCol: null,
      resetFullSvg: false
    }
  },
  methods:{
    onSampleClick(data){
      this.newSample = data;
      this.notifyFooter();
    },
    notifyFooter(){
      console.log("emitting on-workspace-settingup");
      this.$emit('on-workspace-settingup');
    },
    showColorsInCurrentPallete(svgColors){
      if(svgColors!=null){
        this.ready = true;
        this.workspaceSpan = 16;
        this.svgColors = svgColors;
      }
    },
    applyColors(newcolors){
      if(newcolors!=null){
        this.newColors = Array.from(newcolors);
      }else{
        console.log("new colors is null!");
      }
    },
    readyForNewUpload(){
      this.ready = false;
      this.workspaceSpan = 12;
    },
    refreshPalettes(){
      console.log("notice to refresh!");
      this.refresh = !this.refresh;
    },
    userHoverOnCurrentColor(event){
      this.highlightCol = event
    },
    resetSvgContent(){
      this.resetFullSvg = !this.resetFullSvg;
    }
  }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.leftCol{
  width: 40%;
  display: inline-block;
}

.rightCol{
  width: 60%;
  display: inline-block;
}

.readyLeftCol{
  width: 30%;
  display: inline-block;
}

.readyRightCol{
  width: 70%;
  display: inline-block;
}

@media screen and (max-width: 800px) {
  .leftCol, .rightCol, .readyLeftCol, .readyRightCol {
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}

</style>
