import { SVG, Color as Color, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js' // eslint-disable-line no-unused-vars

//###########################
//#### PUBLIC METHODS #######
//###########################

//Gets all the fill colors from an svg
function getColorsFromSvg(obj){
	var colors = new Set();
	if(obj!=null){
		var svg = SVG(obj);

		//Iterae through all tags
		svg.each(function(i, children) { // eslint-disable-line no-unused-vars
			colors = extractColor(colors, this);
		}, true);

		if(colors.size == 0){ //if svg relies on default color and doesnt have a fill attribute #CASE: NO COLOR
			colors.add('#000000')
		}

	}else{
		console.log("obj is null!")
	}

	return Array.from(colors);
}

function setColorsOnSvg(obj, newColors, oldColors){
	//console.log("set colors called with: new:"+newColors+" old:"+oldColors);
	normalizeColorList(newColors);
	if(obj!=null && newColors.size == oldColors.size){
		var svg = SVG(obj);
		svg.each(function(i, children) { // eslint-disable-line no-unused-vars
			
			applyColor(this, newColors, oldColors);
			/*if(oldColors.length > 1){
				var rawCol = this.attr('fill');
				if(isValidColorHolderElement(this) && isColor(rawCol)){
					var newCol = getNewColor(getColor(rawCol), oldColors, newColors);
					this.attr('fill', newCol);
				}
			}else{ 
				//For #CASE: NO COLOR mentioned in getColorsFromSvg()
				//Not the optimal solution. applies fill to all tag including title.
				//Todo: Needs optimization to consider only elements that support 'fill'
				this.attr('fill', newColors[0]);
			}*/

		}, true);
		return svg.svg();
	}else{
		console.log("obj is null!")
	}
	return obj;
}

const HIGHLIGHT_COLOR = '#00ffff';

//Highlights the element with the given color
function highlightColor(obj, col){
    return setColorOnSvg(obj, HIGHLIGHT_COLOR, col)
}

function unhighlightColor(obj, col){
    return setColorOnSvg(obj, col, HIGHLIGHT_COLOR)
}

function setColorOnSvg(obj, newCol, oldCol){
	if(obj && oldCol && newCol){
		oldCol = getColor(oldCol)
		newCol = getColor(newCol)
		var svg = SVG(obj);
        svg.each(function(i, children) { // eslint-disable-line no-unused-vars
			applySingleColor(this, newCol, oldCol);
        }, true);
        return svg.svg();
    }else{
        console.log("obj or col is null!")
    }
    return obj;
}

function setSizeTo(obj, width, height){
	if(obj!=null){
		var svg = SVG(obj);
		svg.size(width,height);
		return svg.svg();
	}
	return obj;
}

function getSize(obj){
	if(obj!=null){
		var svg = SVG(obj);
		console.log("loaded size: "+[svg.width(), svg.height()]);
		return [svg.width(), svg.height()];
	}
	return [0,0]
}


//###########################
//#### PRIVATE METHODS ######
//###########################

//Extracts color from an svg element / tag.
function extractColor(colors, element){
	if(isValidColorHolderElement(element)){
		var rawCol = getFillValue(element);
		//console.log("rawCol:"+rawCol);
		
		for(var col of rawCol){
			if(isColor(col)){
				colors.add(getColor(col));
			}
		}
	}
	return colors;
}

//To know if the svg element / tag is a valid color holding element
function isValidColorHolderElement(element){
	if(element.type == 'title') return false; //Expecting more tags to be added
	return true;
}

//Get the fill values in an element. Style tag can hold many fill values in the classes. Other tags would hold one fill color.
function getFillValue(element){
	if(element.type=='style'){
		var css = element.node.textContent;
		var matches = css.match(/fill:\s*#[A-Za-z0-9]*(;|})/g); //pattern to match fill colors in css classes within style tag.
		for(var i in matches){
			matches[i] = matches[i].replace('fill:','')
			matches[i] = matches[i].replace(' ','')
			matches[i] = matches[i].replace(';','')
			matches[i] = matches[i].replace('}','')
		}
		//console.log(matches);
		return matches;
	}else{
		return [element.attr('fill')];
	}
}

//If a fill value is a valid color or not. 
//Elements without fill can return none or #000000. Nira currently doesnt handle url defs.
function isColor(value){ 
	return value!=null && value!=undefined && value!='none' && !value.startsWith('url') && value!='#000000'; //Although #000000 is a valid color, empty tags return black as the color. so avoiding it with this hack. 
}

//Get the original hex format of a color.
function getColor(value){
	if(!value.toString().startsWith('#')){
		value = colourNameToHex(value);
	}
	
	return new Color(value).toHex().toLowerCase();
}

function normalizeColorList(list){
	for(var index in list){
		list[index] = getColor(list[index]);
	}
}

function getNewColor(color, oldColors, newColors){
	for(var index in oldColors){
		if(color == oldColors[index]){
			return newColors[index];
		}
	}
	console.log("color not accounted : "+color);
	return newColors[0]; //should never happen
}

//Set the fill values in an element. Style tag can hold many fill values in the classes. Other tags would hold one fill color.
function setFillValue(element, oldCol, newCol){
	if(element.type=='style'){
		element.node.textContent = element.node.textContent.replace(oldCol, newCol);
	}else{
		element.attr('fill', newCol);
	}
}

function applyColor(element, newColors, oldColors){
	if(isValidColorHolderElement(element)){
		var rawCol = getFillValue(element);
		
		for(var col of rawCol){
			if(isColor(col) || (oldColors.length==1)){ //if the svg does not have a fill color mentioned, then by default black is used. for that purpose, we allow the single color to be applied without worrying if the tag contained a fill previously or not.
				var newColToApply = getNewColor(getColor(col), oldColors, newColors);
				setFillValue(element, col, newColToApply);
			}
		}
	}
}

//Same as applyColor. Except this takes in only one color as input and replace it
function applySingleColor(element, newColor, oldColor){
	if(isValidColorHolderElement(element)){
		var rawCol = getFillValue(element);
		
		for(var col of rawCol){
			if(isColor(col) && getColor(col)==oldColor){
				//element.fill({ color: '#0ff', width:2 })
				setFillValue(element, col, newColor);
			}
		}
	}
}


//###########################
//###### UTILS ##############
//###########################

function colourNameToHex(colour)
{
    var colours = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
    "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
    "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
    "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
    "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
    "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
    "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
    "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
    "honeydew":"#f0fff0","hotpink":"#ff69b4",
    "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
    "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
    "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
    "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
    "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
    "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
    "navajowhite":"#ffdead","navy":"#000080",
    "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
    "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
    "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
    "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
    "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
    "violet":"#ee82ee",
    "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
    "yellow":"#ffff00","yellowgreen":"#9acd32"};

    if (typeof colours[colour.toLowerCase()] != 'undefined')
        return colours[colour.toLowerCase()];

    return false;
}




export {getColorsFromSvg, setColorsOnSvg, setSizeTo, getSize, highlightColor, unhighlightColor, setColorOnSvg}