<template>
  
  <el-container>
    <el-header class="brand" :class="(workspaceSetup)? 'bottomShade': ''">
      <Header v-on:on-show-how-to="enableHowTo()"/>
    </el-header>
    <el-main>
      <HowTo v-if="showHowTo"/>
      <Main  v-else v-on:on-workspace-settingup="onWorkspaceSetup()" />
    </el-main>
    <Footer :toggleFooterPos="workspaceSetup" />
  </el-container>

</template>

<script>
import Header from './components/Header.vue'
import Main from './components/Main.vue'
import HowTo from './components/HowToPage.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Main,
    Footer,
    HowTo
  },
  data(){
    return{
      workspaceSetup : false,
      showHowTo: false
    }
  },
  methods:{
    onWorkspaceSetup(){
      console.log("workspace setup is set to true");
      this.workspaceSetup = true
    },
    enableHowTo(){
      console.log("setting show how to true");
      this.showHowTo = true;
      this.onWorkspaceSetup();
    }
  }
}
</script>

<style>
#app {
  font-family: Source Sans Pro, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.btn{
  background-color: #4654A3 !important;
  border: #4654A3 !important;
}

.btn:hover{
  background-color: #270949 !important;
  border: #270949 !important;
}

.btn-highlight, .btn-dark:hover{
  background-color: #F95B3D !important;
  border: #F95B3D !important;
}

.btn-dark, .btn-highlight:hover{
  background-color: #270949 !important;
  border: #270949 !important;
}

.brand-dark{
  background-color: #270949 !important;
}
.brand-light{
  background-color: #4654A3 !important;
}

.btntxt{
  color: #4654A3 !important;
}

.btntxt:hover{
  color: #270949 !important;
}

.txt{
  color: #270949;
}

.bottomShade{
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
}

body {
  height: 100%;
  margin: 0;
  background-color: white;
}

.heading{
  font-size: x-large;
  font-weight: 500;
}

.subheading{
  font-size: large;
  font-weight: 300;
}

.elevatedStage{
  border-radius: 8px; 
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); 
  background-color:white
}

.brand{
  background: white; /**linear-gradient(to right, white, whitesmoke); **/
}

.bg{
  background-color: whitesmoke;
}

.brand2{
  background-color: #80B918;
}

</style>
