<template>
  <div class="colorpickerlistholder">
    <div id="pickerHolder" class="fullWidth">
      <!--<el-color-picker  
      v-model="curColors[index]" 
      v-for="(col,index) in curColors" :key="index"
      v-on:change="onChange" 
      @mousemove="onHover(col)"
      style="margin-right:4px"
      ></el-color-picker>-->
      <div v-for="(col,index) in curColors" :key="index" @mouseenter="onHover(col)" @mouseleave="onHover(null)"  style="margin-right:4px; display: inline">
        <el-color-picker  
        v-model="curColors[index]" 
        v-on:change="onChange" ></el-color-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ColorPickerListHolder',
    props: {
        'colors': Array
    },
    data() {
        return {
            curColors: Array.from(this.colors)
        }
    },
    watch: {
      colors(newCols, oldCols) { // eslint-disable-line no-unused-vars
        //console.log("prop new: "+newCols+" old:"+oldCols);
        this.curColors = newCols;
      }
    },
    methods:{
        onChange(){
          console.log('on change');
          this.$emit('on-user-select-color-change', Array.from(this.curColors));
        },
        onHover(col){
          this.$emit('on-user-hover-on-color', col);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.inset{
  padding: 12px;
}

.leftAlign{
  float: left; 
}

.fullWidth{
  width: 100%; 
  display: inline;
}

.colorItem{
  display: inline-block;
  margin: 4px;
}

</style>
