<template>
  <div class="colorlistholder">
    <div class="fullWidth">
      <div id="colorCircle" class="colorItem" 
      :style="{ background: col, width: size+'px', height: size+'px'}" 
      v-for="col in list" :key="col">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorListHolder',
  props: ['colors', 'size'],
  data() {
      return {
        list: Array.from(this.colors)
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.leftAlign{
  float: left; 
}

.colorItem{
  display: inline-block;
  margin: 4px 2px 0px 2px;
  border-radius: 4px;
  border-style: solid;
  border-color: whitesmoke;
  border-width: 1px;
}

</style>
