<template>
  <div class="explCards container">
    
    <div class="paircontainer">
        <div class="pair tp">
            <p class="highlight">Auto generated palettes</p>
            <p class="normal">Choose from a variety of colorful auto generated palettes that adapts to the contrast ratio of the colors in the svg</p>
        </div>
        <div class="pair" >
            <img class="ip" alt="Palette image" src="@/assets/pallettes.png"/>
        </div>
    </div>

    <div class="paircontainer">
        <div class="pair" style="vertical-align: top;">
            <img class="ip" alt="Color schemes" src="../assets/pallette_types.png"/>
        </div>
        <div class="pair tp">
            <p class="highlight">Multiple color schemes</p>
            <p class="normal">Different colors schemes to select from including monochromatic, complementary and triadic</p>
        </div>
    </div>

    <div class="paircontainer">
        <div class="pair tp">
            <p class="highlight">Customize individual elements</p>
            <p class="normal">Change the shade or hue of any individual color element. The chosen colors's lightness is remembered to generate further pallettes!</p>
        </div>
        <div class="pair" >
            <img class="ip" alt="Color selection" src="../assets/color_selector.png"/>
        </div>
    </div>

    <div style="margin-top:40px">
        <a href="https://www.producthunt.com/posts/nira?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-nira" target="_blank">
          <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=307773&theme=light" alt="Nira - Change&#0032;the&#0032;color&#0032;scheme&#0032;of&#0032;an&#0032;SVG&#0032;in&#0032;an&#0032;instant | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" />
        </a>
    </div>

<!--
<el-scrollbar>
    <div class="scrollbar-flex-content">

    <div class="scrollbar-item">
      
    <blockquote class="twitter-tweet"><p lang="en" dir="ltr"><a href="https://twitter.com/Bhuvanesh_Shan?ref_src=twsrc%5Etfw">@Bhuvanesh_Shan</a> Nira is great! … I like that you can resize. Would be fun to have a few sliders that would make radical shifts to the palettes. 🙏🏼🙌</p>&mdash; Rowe Morehouse 🌐 (@rowemore) <a href="https://twitter.com/rowemore/status/1474648511748591617?ref_src=twsrc%5Etfw">December 25, 2021</a></blockquote> 
</div>
    <div class="scrollbar-item">
    <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Nira - Change the color scheme of any SVG in an instant<br><br>Build by <a href="https://twitter.com/Bhuvanesh_Shan?ref_src=twsrc%5Etfw">@Bhuvanesh_Shan</a><br><br>Hope I would have found this tool earlier when I was building my website. But will definitely keep it handy for my future projects.<br><br>Check it out 👇<a href="https://t.co/trlKcyRuvn">https://t.co/trlKcyRuvn</a></p>&mdash; Gurjot Singh 👋 (@gurjotsingh4398) <a href="https://twitter.com/gurjotsingh4398/status/1434521538401685508?ref_src=twsrc%5Etfw">September 5, 2021</a></blockquote> 
</div>
    </div>
  </el-scrollbar>-->

    <div style="margin-top:80px">
        <p class="highlight">Try now with one of the samples below</p>
        <img style="height:80px" alt="Down arrow" src="../assets/downarrow.png"/>
        <Samples v-on:on-sample-selected="onSampleClick($event)"/>
    </div>

  </div>
</template>

<script>
import Samples from './Samples.vue'

export default {
  name: 'ExplCards',
  components:{
      Samples
  },
  methods:{
      onSampleClick(data){
          this.$emit('on-sample-selected', data);
      }
  }    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.scrollbar-flex-content {
  display: flex;
}

.scrollbar-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 500px;
  margin: 40px;
  text-align: center;
  border-radius: 4px;
}

.container {
  margin-top: 0px;
  margin-bottom: 40px;
}

.vertical-center {
  margin: 4px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.paircontainer{
    margin-top:80px;
}

.tp{
    max-width: 30%;
    -ms-transform: translateY(-20%);
    transform: translateY(-20%);
}

@media screen and (max-width: 800px) {
  .tp {
    max-width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
  .paircontainer{
      margin-top:40px;
  }
}

.ip{
    border-radius: 10px;
    width: 260px;
    box-shadow: 3px 3px 10px #ccc;
    -webkit-box-shadow: 3px 3px 10px #ccc;
    -moz-box-shadow: 3px 3px 10px #ccc;
}

.pair{
    display: inline-block;
    padding: 12px;
    margin: 2px;
}

.highlight{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: #2F2E41;
}

.normal{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #8C8A8A;
}

</style>
