import { createApp } from 'vue'

//Element plus
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

//SVG
import SVG from '@svgdotjs/svg.js'

//Color-scheme
import ColorScheme from 'color-scheme';

import App from './App.vue'

const app = createApp(App)
app.use(ElementPlus)
app.use(SVG)
app.use(ColorScheme)
//app.component('Slider', Slider)
app.mount('#app')


