<template>
  <div id="sliders-holder">
    <HueSlider v-for="(item,index) in hues" :key='index' v-model:hue='hues[index]' v-on:hue-update="onUserInput()" />
  </div>
</template>

<script>
import HueSlider from './HueSlider.vue'

export default {
  name: 'ColorSliders',
  components: {
    HueSlider
  },
  props: {
    count: Number
  },
  data() {
      return {
        hues: [0]
      }
    },
  watch:{
    count(newVal, oldVal){
      if(oldVal < newVal){
        while(oldVal < newVal){
          this.hues.push(0)
          oldVal++
        }
      }else if(oldVal > newVal){
        this.hues = this.hues.slice(0, newVal);
      }
    }
  },
  methods: {
    onUserInput(){
      this.$emit("on-user-select-hues-change", Array.from(this.hues));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.leftAlign{
  float: left; 
}

.colorItem{
  display: inline-block;
  margin: 4px 2px 0px 2px;
  border-radius: 4px;
}

</style>
